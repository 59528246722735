import React from 'react';
import { useTranslation } from 'next-i18next';

export type ContactUsLinkProps = {
  className?: string;
  lowercase?: boolean;
};

export const ContactUsLink = ({ className, lowercase }: ContactUsLinkProps) => {
  const { t } = useTranslation();
  return (
    <a className={className} href={`mailto:${t('contactUs.email')}?subject=${t('contactUs.subject')}`}>
      {lowercase ? t('contactUs.label').toLowerCase() : t('contactUs.label')}
    </a>
  );
};
