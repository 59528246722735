import { Button, ButtonColor, ButtonPull, FormInput, FormPasswordInput, Grid } from '@alirosoftware/web-components';
import React from 'react';
import { HiOutlineLockClosed, HiOutlineMail } from 'react-icons/hi';
import { useTranslation } from 'next-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { GetMeDocument, LoginInput, useLoginMutation, UserFragment } from '../../../graphql/_generated';
import { FormServerError } from '../../shared/forms/server-error';

export type EmailPasswordLoginFormProps = {
  onSuccess: (user: UserFragment) => void;
};

export const EmailPasswordLoginForm = ({ onSuccess }: EmailPasswordLoginFormProps) => {
  const { t } = useTranslation();

  const [login, { error }] = useLoginMutation({
    refetchQueries: [GetMeDocument],
    awaitRefetchQueries: true,
    onCompleted: ({ login: userResponse }) => onSuccess(userResponse),
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string().label(t('email')).email().required(),
    password: Yup.string().label(t('password')).required(),
  });

  return (
    <Formik<LoginInput>
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={(values) => login({ variables: { input: values } })}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Grid.Container fluid>
            <FormServerError error={error} />
            <Grid.Row>
              <Grid.Column colspan={12}>
                <FormInput
                  name="email"
                  testId="login-email"
                  label={t('email')}
                  placeholder={t('email')}
                  tabIndex={4}
                  autoFocus
                  icon={HiOutlineMail}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column colspan={12} mb={4}>
                <FormPasswordInput
                  name={'password'}
                  testId="login-password"
                  label={t('password')}
                  placeholder={t('password')}
                  tabIndex={5}
                  icon={HiOutlineLockClosed}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column colspan={12}>
                <Button
                  testId="login"
                  onClick={handleSubmit}
                  color={ButtonColor.Primary}
                  pull={ButtonPull.Right}
                  loading={isSubmitting}
                  tabIndex={6}
                  className="u-m-0"
                >
                  Login
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid.Container>
        </form>
      )}
    </Formik>
  );
};
