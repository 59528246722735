/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import { useFormikContext } from 'formik';

export type FormServerErrorProps = { error: ApolloError | undefined; pathPrefix?: string };

export const FormServerError = ({ error, pathPrefix }: FormServerErrorProps) => {
  const { setErrors } = useFormikContext();
  const [genericError, setGenericError] = React.useState<string>();

  useEffect(() => {
    setGenericError(undefined);

    if (!error || !error.graphQLErrors || error.graphQLErrors.length === 0) {
      return;
    }

    const {
      graphQLErrors: [graphQLError],
    } = error;
    const isValidationError = graphQLError.message === 'Bad Request Exception';

    const validationErrors: Record<string, string> = isValidationError
      ? // @ts-ignore
        graphQLError.extensions?.exception?.response
      : undefined;
    if (validationErrors) {
      setErrors(
        pathPrefix
          ? Object.keys(validationErrors).reduce<Record<string, string>>((carry, key) => {
              carry[`${pathPrefix}.${key}`] = validationErrors[key];
              return carry;
            }, {})
          : validationErrors,
      );
    } else {
      setGenericError(graphQLError.message);
    }
  }, [error, pathPrefix, setErrors]);

  if (!genericError) {
    return null;
  }

  return (
    <div className="has-error">
      <span className="error-messages">{genericError}</span>
    </div>
  );
};
