import { Grid } from '@alirosoftware/web-components';
import React from 'react';
import { EmailPasswordLoginForm, EmailPasswordLoginFormProps } from './email-password-login-form';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { FORGOT_PASSWORD_PATH } from '../../../constants/routes';

export type LoginModalProps = EmailPasswordLoginFormProps;

export const LoginModal = ({ onSuccess }: LoginModalProps) => {
  const { t } = useTranslation();

  return (
    <Grid.Container fluid>
      <Grid.Row>
        <Grid.Column colspan={12}>
          <EmailPasswordLoginForm onSuccess={onSuccess} />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column colspan={12} mt={4} textAlign="center">
          <Link href={FORGOT_PASSWORD_PATH} tabIndex={10} aria-label={t('forgotPassword')}>
            {t('forgotPassword')}
          </Link>
        </Grid.Column>
      </Grid.Row>
    </Grid.Container>
  );
};
