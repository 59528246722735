import { useGetMeQuery } from '../../../graphql/_generated';
import { Header, HeaderNav } from '@alirosoftware/web-components';
import React from 'react';
import { AvatarDropdownMenu } from './avatar-dropdown-menu';
import { ContactUsLink } from '../contact-us-link';
import { LogoutLink } from './logout-link';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const theme = publicRuntimeConfig?.theme;

export type GenericHeaderProps = {
  showNav?: boolean;
};

export const GenericHeader = ({ showNav = true }: GenericHeaderProps) => {
  const { data: me } = useGetMeQuery({ fetchPolicy: 'cache-first' });

  const logo =
    theme === 'jff'
      ? 'https://images.onaliro.com/inspring/jff_logo.png'
      : 'https://images.onaliro.com/inspring/inspring_logo.png';

  return (
    <Header nav={<div />} logo={logo}>
      {showNav ? (
        <HeaderNav>
          <ContactUsLink className="u-m-0 u-mr-4 u-color-black" />
          {me?.me ? (
            <>
              <LogoutLink />
              <AvatarDropdownMenu />
            </>
          ) : null}
        </HeaderNav>
      ) : null}
    </Header>
  );
};
