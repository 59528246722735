import React, { useCallback } from 'react';
import { AppWrapper, Box, Grid } from '@alirosoftware/web-components';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import nextI18nNextConfig from '../../next-i18next.config';
import { GenericHeader } from '../../components/shared/header/generic-header';
import { GetServerSidePropsContext } from 'next';
import Head from 'next/head';
import { LoginModal } from '../../components/onboarding/login/login-modal';
import { UserFragment } from '../../graphql/_generated';
import { userSignupLoginRedirectLocation } from '../../utils/user';
import { useRouter } from 'next/router';
import { PRIVACY_PATH, TERMS_PATH } from '../../constants/routes';

export const getServerSideProps = async ({ locale }: GetServerSidePropsContext) => ({
  props: {
    ...(await serverSideTranslations(locale || '', ['common'], nextI18nNextConfig)),
  },
});

export function Login() {
  const router = useRouter();

  const onSuccess = useCallback(
    (user: UserFragment) => {
      let redirectLocation = userSignupLoginRedirectLocation(user);
      if (router.query.redirect && !router.query.redirect.includes('_next')) {
        redirectLocation = router.query.redirect as string;
      }

      window.location.href = redirectLocation;
    },
    [router.query.redirect],
  );

  return (
    <AppWrapper
      header={<GenericHeader showNav={true} />}
      footer={false}
      wrapperClassName="login-page"
      links={{ terms: TERMS_PATH, privacy: PRIVACY_PATH }}
    >
      <Head>
        <title>inSpring - Login</title>
      </Head>
      <Grid.Container fluid>
        <Grid.Row>
          <Grid.Column
            colspan={12}
            colspanXl={4}
            colspanLg={6}
            colspanMd={8}
            colspanSm={11}
            className="u-pull-center"
            mb={4}
            textAlign="center"
          >
            <h1>Login</h1>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column
            colspan={12}
            colspanXl={4}
            colspanLg={6}
            colspanMd={8}
            colspanSm={11}
            className="u-pull-center"
            p={0}
          >
            <Box className="card card-vertical" p={2} pl={4} pr={4}>
              <LoginModal onSuccess={onSuccess} />
            </Box>
          </Grid.Column>
        </Grid.Row>
      </Grid.Container>
    </AppWrapper>
  );
}

export default Login;
